import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { useTranslation } from 'react-i18next';

import abstractShapes19Svg from '../../assets/svg/components/abstract-shapes-19.svg';
import angularIcon from '../../assets/svg/technologies/angular.svg';
import apiGatewayIcon from '../../assets/svg/technologies/aws-api-gateway.svg';
import lambdaIcon from '../../assets/svg/technologies/aws-lambda.svg';
import awsIcon from '../../assets/svg/technologies/aws.svg';
import dynamoDbIcon from '../../assets/svg/technologies/dynamo-db.svg';
import gatsbyIcon from '../../assets/svg/technologies/gatsby_icon.svg';
import nodeJsIcon from '../../assets/svg/technologies/nodejs.svg';
import pwaIcon from '../../assets/svg/technologies/pwa.svg';
import rxjsIcon from '../../assets/svg/technologies/rxjs.svg';
import serverlessIcon from '../../assets/svg/technologies/serverless.svg';
import stripeIcon from '../../assets/svg/technologies/stripe.svg';
import typescriptIcon from '../../assets/svg/technologies/typescript.svg';
import LocalizedLink from '../../components/LocalizedLink';
import SEO from '../../components/Seo';
import SVG from '../../components/Svg';
import { getSchedulinoLink } from '../../i18n';

const SchedulinoCaseStudyPage = () => {
  const { i18n, t } = useTranslation();

  return (
    <>
      <SEO
        title={t('meta.caseStudies.schedulino.title')}
        lang={i18n.language}
        description={t('meta.caseStudies.schedulino.description')}
      />

      <div className="gradient-y-gray position-relative">
        <div className="space-top-3 space-top-lg-2 space-bottom-2 space-bottom-lg-3">
          <div className="container mt-lg-5">
            <div className="row">
              <div className="col-lg-5 align-self-center mb-5 mb-md-0 ">
                <div className="mb-4">
                  <h1 className="display-4">Schedulino</h1>
                  <p className="font-weight-bold">{t('caseStudies.1.subtitle')}</p>
                  <p>{t('caseStudies.1.text')}</p>
                </div>

                <div>
                  <LocalizedLink
                    to="/contact"
                    className="btn btn-primary transition-3d-hover text-capitalize mb-2 mb-sm-0 mr-3"
                  >
                    {t('label.findMore')}
                  </LocalizedLink>
                  <a
                    href={getSchedulinoLink(i18n.language)}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn btn-outline-primary transition-3d-hover text-capitalize"
                  >
                    {t('label.visitWebsite')}
                  </a>
                </div>
              </div>
              <div className="col-lg-7 align-self-center">
                <figure className="w-100 text-center">
                  <StaticImage
                    className="img-fluid rounded-pseudo shadow-lg"
                    src="../../assets/images/case-studies/schedulino.png"
                    alt="Schedulino SaaS application"
                  />
                </figure>
              </div>
            </div>
          </div>
        </div>

        <figure>
          <svg
            preserveAspectRatio="none"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 1920 100.1"
          >
            <path fill="#fff" d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z" />
          </svg>
        </figure>
      </div>

      <section className="container space-2 space-bottom-lg-3 space-top-lg-4">
        <div className="row justify-content-lg-between align-items-lg-center">
          <div className="col-lg-5 mb-5 mb-lg-0" data-aos="fade-right">
            <h2>{t('label.challenge')}</h2>
            <p className="text-pre-line">{t('caseStudies.1.challenge')}</p>
          </div>
          <figure className="col-lg-6 text-center" data-aos="fade-left">
            <StaticImage
              className="img-fluid rounded-pseudo shadow-lg"
              src="../../assets/images/case-studies/schedulino_add_shift.png"
              alt="Schedulino Case Study"
            />
          </figure>
        </div>
      </section>

      <SVG />

      <section className="bg-light">
        <div className="container space-2 space-lg-3">
          <div className="row justify-content-lg-between align-items-lg-center">
            <div className="col-lg-5 order-lg-2 mb-5 mb-lg-0" data-aos="fade-left">
              <h2>{t('label.solution')}</h2>
              <p className="text-pre-line">{t('caseStudies.1.solution')}</p>
            </div>
            <figure className="col-lg-6 order-lg-1 text-center" data-aos="fade-right">
              <StaticImage
                className="img-fluid"
                src="../../assets/images/case-studies/schedule_mobile_iphone8silver_portrait.png"
                alt="Schedulino Case Study"
              />
            </figure>
          </div>
        </div>
      </section>

      <SVG type="ShapeBottom" />

      <section className="container space-2 space-top-lg-3 space-bottom-lg-2">
        <div className="row justify-content-center mb-5">
          <h2 className="text-center">{t('label.technologies')}</h2>
        </div>

        <div className="row align-items-center text-center">
          <figure className="col-4 col-lg-3 pb-4">
            <img
              className="max-w-11rem max-w-md-19rem mx-auto"
              src={typescriptIcon}
              alt="typescript"
            />
          </figure>
          <figure className="col-4 col-lg-3 pb-4">
            <img className="max-w-11rem max-w-md-19rem mx-auto" src={angularIcon} alt="angular" />
          </figure>
          <figure className="col-4 col-lg-3 pb-4">
            <img className="max-w-11rem max-w-md-19rem mx-auto" src={nodeJsIcon} alt="nodejs" />
          </figure>
          <figure className="col-4 col-lg-3 pb-4">
            <img className="max-w-11rem max-w-md-19rem mx-auto" src={pwaIcon} alt="pwa" />
          </figure>
          <figure className="col-4 col-lg-3 pb-4">
            <img className="max-w-11rem max-w-md-19rem mx-auto" src={stripeIcon} alt="stripe" />
          </figure>
          <figure className="col-4 col-lg-3 pb-4">
            <img className="max-w-7rem max-w-md-11rem mx-auto" src={gatsbyIcon} alt="aws" />
          </figure>
          <figure className="col-4 col-lg-3 pb-4">
            <img className="max-w-11rem max-w-md-19rem mx-auto" src={rxjsIcon} alt="rxjs" />
          </figure>
          <figure className="col-4 col-lg-3 pb-4">
            <img
              className="max-w-11rem max-w-md-19rem mx-auto"
              src={serverlessIcon}
              alt="serverless"
            />
          </figure>
          <figure className="col-4 col-lg-3 pb-4">
            <img className="max-w-11rem max-w-md-19rem mx-auto" src={awsIcon} alt="aws" />
          </figure>
          <figure className="col-4 col-lg-3 pb-4">
            <img className="max-w-11rem max-w-md-19rem mx-auto" src={dynamoDbIcon} alt="aws" />
          </figure>
          <figure className="col-4 col-lg-3 pb-4">
            <img className="max-w-11rem max-w-md-19rem mx-auto" src={lambdaIcon} alt="aws" />
          </figure>
          <figure className="col-4 col-lg-3 pb-4">
            <img className="max-w-5rem max-w-md-11rem mx-auto" src={apiGatewayIcon} alt="aws" />
          </figure>
        </div>
      </section>

      <section
        className="container space-2 space-lg-3"
        style={{
          backgroundImage: `url(${abstractShapes19Svg})`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <div className="w-md-80 w-lg-50 mx-md-auto text-center">
          <h2>{t('label.buildProjectWithCodutech')}</h2>
          <p>{t('homePage.footer.subtitle')}</p>

          <LocalizedLink to="/contact" className="btn btn-primary transition-3d-hover mt-2">
            {t('label.getInTouch')}
          </LocalizedLink>
        </div>
      </section>
    </>
  );
};

export default SchedulinoCaseStudyPage;
